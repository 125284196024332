import filldropDown from "assets/icons/fillDropDown.png"
import ProfilePopup from "entities/ProfilePupup/ProfilePopup"
import { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUser } from "@fortawesome/free-solid-svg-icons"
import "./index.scss"

const MenuProfile = () => {
	const [isActive, setIsActive] = useState<boolean>(false)
	const togglePopup = () => {
		setIsActive(!isActive)
	}
	return (
		<div className="profile_menu_conteiner relative">
			{/* @ts-ignore */}
			{/* <FontAwesomeIcon icon="" /> */}
			<FontAwesomeIcon icon={faUser} style={{ lightingColor: "GrayText" }} className="profile_picture" onClick={togglePopup} color="white" fill="none" lightingColor={"red"} />
			{/* <div onClick={togglePopup} className="profile-menu-text text-white">Me</div>
			<img src={filldropDown} alt="" className="fillDropwDown text-white" onClick={togglePopup} /> */}
			{isActive && <div className="black-window-popup">
				<ProfilePopup closePopup={togglePopup} /></div>}
		</div>
	)
}
export default MenuProfile